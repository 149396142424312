/**
 * Yes, I know this is a horrible way to show my playlists and it could be so
 * much better. Unfortunately, Spotify has decided to require that all API
 * requests are signed, even for public information such as, well, my public
 * playlists. I don't want to incur server costs just to show music my website,
 * so this is just how it has to be. Thanks, Spotify.
 */

export const playlists = [
  {
    id: '38oWZplFPkJgB0g',
    description: 'Perfect for every suburban kitchen countertop.',
  },
  {
    id: 'DdAN8AdCaJvlX8l',
    description: 'Known to induce RLS (Repetitive Listening Syndrome).',
  },
  {
    id: '11zBJyYI8DdZ1yZ',
    description: "Life lesson #42: don't question why you like things.",
  },
  {
    id: 'd2b05ZWCM0VBX9B',
    description: 'Inject the electricity into my veins, please.',
  },
];
