export const volunteering = [
  {
    content: 'East Liberty Presbyterian Church',
    extra: 'Tackling food insecurity in low-income regions of Pittsburgh',
    icon: 'fas fa-utensils',
  },
  {
    content: 'Allderdice High School',
    extra: 'Math peer tutoring',
    icon: 'fas fa-square-root-alt',
  },
];
