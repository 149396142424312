export const more = [
  {
    url: 'https://twitter.com/fishdev5/following',
    icon: 'fab fa-twitter',
    name: 'My Twitter follows',
  },
  {
    url: 'https://blacklivesmatters.carrd.co',
    icon: 'fas fa-fist-raised',
    name: 'Black Lives Matter'
  },
  {
    url: 'https://quantumstack.xyz',
    icon: 'fas fa-cubes',
    name: 'QuantumStack',
    description: 'My collaborative projects',
  },
  {
    url: 'https://github.com/fishdev/fishdev.github.io/tree/dev',
    icon: 'fab fa-github',
    name: 'This site on GitHub',
  },
];
